<template>
  <div class="page">
    <header>
      <div class="swiper">
        <van-swipe :autoplay="2000">
          <van-swipe-item v-for="(image, index) in images" :key="index">
           <!-- <img v-lazy="image" />-->
            <img :src="good.bigImagePath" alt="">
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 返回 -->
      <van-nav-bar
        title=""
        left-text=""
        left-arrow
        @click-left="onClickLeft"
      />
      <!-- 商品价格 -->
      <div class="goodAddStore">
        <div class="good">
          <div class="goodPrice">
            <span>¥</span>
            <span>{{ good.productPrice }}</span>
          </div>
          <div class="goodSend">
            <span>包邮</span>
          </div>
          <span class="goodStore"> {{ good.shopName }}</span>
        </div>
        <div class="store">
          <span>{{ good.shopName }}</span>
          <span>{{ good.productName }}</span>
        </div>
      </div>
      
    </header>
    <section class="ownMain">
      <div class="divisionZone"></div>
      <div class="goodInfo">
        <van-divider>商品简介</van-divider>
        <div class="goodContent">
        <!--  <p>{{good.productContent}}</p>-->
          <quill-editor
                  v-model="good.productContent"
                  :options="editorOption"
                  @blur="onEditorBlur($event)"
                  @focus="onEditorFocus($event)"
                  @change="onContentChange($event)"
                  @ready="onEditorReady($event)">
          </quill-editor>

          <!--<img :src="good.bigImagePath" alt="">
          <p>护肤在现代已经成为一门学问，人们受皮肤问题困扰不再盲目地去商店买各种化妆品来试，而是由皮肤科医生开出处方，提供针对性的护肤建议。随着人们对健康的追求日益高涨和皮肤医学的高速发展，带有更高安全性和有效性的医学护肤品已经成为不可阻挡的一股潮流。</p>
          <img src="./assets/img/flower.png" alt="">-->
        </div>
      </div>
    </section>
    <footer class="ownFoot">
      <span class="text_13" @click="confirmOrder(good.productId)">立即购买</span>
    </footer>
  </div>
</template>
<script>
  import {queryProductById} from "@/api/order";
  import axios from 'axios';
  import { quillEditor } from 'vue-quill-editor'
  import 'quill/dist/quill.core.css'
  import 'quill/dist/quill.snow.css'
  import 'quill/dist/quill.bubble.css'

export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      editorOption: { // 定义富文本编辑器显示
        modules:{
          toolbar: [

        ]
        }
      },
      images: [],
      good:{},
      baseURL:axios.defaults.baseURL,
    };
  },
  mounted(){
    let productId = this.$route.query.productId;
    queryProductById(productId).then(response => {
      if(response.code==200){
        this.good = response.data;
        this.images.push(this.baseURL+response.data.smallImagePath)
        this.good.smallImagePath = this.baseURL+response.data.smallImagePath;
        this.good.bigImagePath = this.baseURL+response.data.bigImagePath;
      }
    });
  },
  methods: {
    onClickLeft(){
      this.$router.go(-1)
    },
    confirmOrder(productId){
      this.$router.push({ name: 'mallConfirmOrder', query: { productId: productId }});
    }
  }
};
</script>
<style scoped lang="css">
  * {
    padding: 0;
    margin: 0;
  }
  html,
  body {
    font-size: 37.5px;
    height: 100%;
  }
  .page{
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  header{
    height: 22rem;
    position: relative;
  }
  footer{
    height: 4rem;
    display: flex;
    align-items: center;
  }
  .ownFoot{
    background-color: #fb743a;
    font-size: 1.28rem;
    color: #fff;
    justify-content: center;
  }
  section{
    flex: 1;
    overflow: auto;
  }
  .ownMain{
    display: flex;
    flex-direction: column;
  }
  .swiper{
    height: 15.7rem;
  }
  .van-swipe, .van-swipe-item, .van-swipe-item img{
    width: 100%;
    height: 100%;
  }
  .van-nav-bar{
    position: absolute;
    top: 0;
    left: 0;
  }
  .goodAddStore{
    padding: 1.06rem 1.86rem;
  }
.good{
  display: flex;
  align-items: center;
}

.goodPrice span:nth-child(1){
  color: #d81e06;
  font-size: 0.9rem;
}
.goodPrice span:nth-child(2){
  color: #d81e06;
  font-size: 1.46rem;
  line-height: 1.46rem;
}
.goodSend span{
  display: inline-block;
  padding: 0.13rem 0.32rem;
  border: 1px solid #c6c5c5;
  border-radius: 15px;
  color: #999;
  font-size: 0.64rem;
  margin: 0 1rem;
}
.goodStore{
  display: inline-block;
  padding: 0.21rem 0.64rem;
  border-radius: 0.7rem;
  color: #bdbdbd;
  font-size: 0.64rem;
  background-color: #f5f5f5;
}
.store{
  margin-top: 0.75rem;
  display: flex;
  align-items: center;
}
.store span:nth-child(1){
  display: inline-block;
  font-size: 0.64rem;
  padding: 0.13rem  0.32rem;
  color:#fff;
  background-color: #fc7a3d;
  border-radius: 3px;
  margin-right: 0.53rem;
}
.store span:nth-child(2){
  color:#fff;
  font-size: 1rem;
  color: #333;
}
.divisionZone{
  height: 1.33rem;
  background-color: #efefef;
}
.goodInfo{
  flex: 1;
  overflow: auto;
  padding: 1.6rem 1.33rem;
}
.van-divider{
  color: #333;
  font-size: 1.12rem;
  font-weight: bold;
}
.goodContent{
  flex: 1;
  overflow: auto;
}
.goodContent p {
  line-height: 1.7rem;
  font-size: 0.9rem;
  padding: 1rem 0;
}
.goodContent img{
  width: 100%;
}
.ql-toolbar.ql-snow{
  border: 1px solid #ccc
}

</style>